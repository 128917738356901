<!-- eslint-disable vue/no-mutating-props -->
<template>
  <px-dropdown
    ref="pxDropdown"
    v-bind="$attrs"
    class="px-dropdown-multi-select bordered-component growing pr-0"
    :disabled="isDisabled || options.length === 0"
    :is-error="isError"
    :is-warning="isWarning"
    lazy
    no-caret
    variant="link"
    :with-arrow="false"
    @hide="hideDropDownContents"
    @show="showDropdownContents"
  >
    <template #button-content>
      <slot>
        <div
          class="px-dropdown-multi-select__button-content d-flex justify-content-between"
        >
          <div
            class="text-left placeholder"
            :class="{
              'px-dropdown-multi-select__button-content--empty':
                options.length === 0,
            }"
          >
            {{ renderedText }}
          </div>
          <px-icon :icon="getDropdownIcon"></px-icon>
        </div>
      </slot>
    </template>
    <b-form-checkbox
      v-if="!hideSelectAll"
      :checked="hasAllSelected"
      class="px-dropdown-multi-select__select-all"
      data-testid="select-all-checkbox"
      @change="changeAll"
    >
      {{ selectAllText }}
    </b-form-checkbox>
    <b-form-checkbox-group
      :checked="selectedValues"
      :options="options"
      stacked
      :text-field="textField"
      :value-field="valueField"
      @change="change"
    />
  </px-dropdown>
</template>

<script>
import PxIcon from './PxIcon.vue';
import PxDropdown from './PxDropdown.vue';
const VALUES_CHANGE_EVENT = 'change';
export default {
  name: 'PxDropdownMultiSelect',
  components: { PxIcon, PxDropdown },
  model: {
    prop: 'selectedValues',
    event: VALUES_CHANGE_EVENT,
  },
  props: {
    isDisabled: { type: Boolean, default: false },
    isError: { type: Boolean, default: false },
    isWarning: { type: Boolean, default: false },
    hideSelectAll: { type: Boolean, default: false },
    options: { type: Array, required: true },
    selectAllText: { type: String, default: 'Select All' },
    selectedText: { type: String, default: null },
    placeholder: { type: String, default: 'Select Values' },
    selectedValues: { type: Array, required: true },
    textField: { type: String, default: 'text' },
    valueField: { type: String, default: 'id' },
  },
  data() {
    return {
      showContents: false,
    };
  },
  computed: {
    renderedText() {
      return this.selectedText || this.placeholder;
    },
    hasAllSelected() {
      return this.selectedValues.length === this.options.length;
    },
    getDropdownIcon() {
      return this.showContents ? 'chevron-up' : 'chevron-down';
    },
  },
  methods: {
    changeAll(state) {
      if (state) {
        this.checkAll();
      } else {
        this.uncheckAll();
      }
    },
    checkAll() {
      this.$emit(
        VALUES_CHANGE_EVENT,
        this.options.map(o => o[this.valueField]),
      );
    },
    uncheckAll() {
      this.$emit(VALUES_CHANGE_EVENT, []);
    },
    change(event) {
      this.$emit(VALUES_CHANGE_EVENT, event);
    },
    showDropdownContents($event) {
      this.showContents = true;
      $event.target.style.width = this.$refs.pxDropdown.$el.clientWidth + 'px';
      this.$emit('show');
    },
    hideDropDownContents() {
      this.showContents = false;
      this.$emit('hide');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@shared/styles/variables';

.px-dropdown-multi-select {
  min-width: 240px;

  &__button-content {
    color: $default-text;
    font-weight: normal;

    &--empty {
      color: $gray;
    }

    &:hover {
      color: $gray;
    }
  }

  &__select-all,
  ::v-deep .custom-control.custom-checkbox {
    min-height: 30px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: $primary;
      color: $white;
    }

    .custom-control-input {
      position: relative;
    }
    .custom-control-label {
      padding: 0 12px;
      flex-grow: 1;
      height: 30px;
      cursor: pointer;
      line-height: 30px;

      &::after,
      &::before {
        border-color: $gray;
        border-width: 1px;
        color: $primary;
        left: 12px;
        top: 4px;
      }
    }
  }

  ::v-deep .dropdown-menu {
    max-height: 20rem;
    overflow-y: auto;
  }
}
</style>
