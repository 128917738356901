import store from '@src/store';

export const DEFAULT_ROUTES = {
  pricing: 'pricing',
  mi_rate_card: 'mortgage-insurance-rate-card',
  lockDesk: 'lock-desk',
  loans: 'loans',
};

const getDefaultRoute = orgUserPermissions => {
  if (orgUserPermissions.can_access_publish_pricing) {
    return DEFAULT_ROUTES.pricing;
  } else if (orgUserPermissions.can_access_lock_desk) {
    return DEFAULT_ROUTES.lockDesk;
  }
  return DEFAULT_ROUTES.loans;
};

export const routes = [
  {
    path: '/pe',
    name: 'pricingHome',
    beforeEnter: (_, __, next) => {
      store.dispatch('core/getPermissions').then(permissions => {
        const defaultRoute = getDefaultRoute(permissions.orgUser);
        next({ name: defaultRoute });
      });
    },
  },
  {
    path: '/pe/pricing',
    name: DEFAULT_ROUTES.pricing,
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/VersionsPage.vue'),
  },
  {
    path: '/pe/pricing/settings',
    name: 'pricing-settings',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/SettingsPage.vue'),
  },
  {
    path: '/pe/mi-rate-card-versions',
    name: DEFAULT_ROUTES.mi_rate_card,
    props: true,
    meta: {
      navitem: 'mi_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/MIRateCardVersionsPage.vue'),
  },
  {
    path: '/pe/pricing/:id',
    name: 'pricing-details',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/VersionDetailsPage.vue'),
  },
  {
    path: '/pe/mi-rate-card-versions-details/:id',
    name: 'mortgage-insurance-rate-card-details',
    props: true,
    meta: {
      navitem: 'mi_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/MIVersionDetailsPage.vue'),
  },
  {
    path: '/pe/loans',
    name: DEFAULT_ROUTES.loans,
    meta: {
      navitem: 'pe_loan_pipeline',
      checkPermissions: true,
      newAppPath: '/pe/loans',
    },
    component: () => import('../pages/LoanPipelinePage.vue'),
  },
  {
    path: '/pe/loans/action-required',
    name: 'pe-loan-pipeline-action-required',
    meta: {
      navitem: 'pe_loan_pipeline',
      checkPermissions: true,
    },
    component: () => import('../pages/LoanPipelineActionRequired.vue'),
  },
  {
    path: '/pe/lock-desk',
    name: DEFAULT_ROUTES.lockDesk,
    props: true,
    meta: {
      navitem: 'pe_lock_desk',
      checkPermissions: true,
    },
    component: () => import('../pages/LockDeskPage.vue'),
  },
  {
    path: '/pe/lock-desk/:losLoanId/request-review/:requestId',
    name: 'lock-request-review',
    props: true,
    meta: {
      navitem: 'pe_lock_request_review',
      checkPermissions: true,
    },
    component: () => import('../pages/LockInformationPage.vue'),
  },
  {
    path: '/pe/lock-desk/:losLoanId/request-details/:requestId',
    name: 'lock-request-details',
    props: true,
    meta: {
      navitem: 'pe_lock_request_details',
      checkPermissions: true,
    },
    component: () => import('../pages/LockInformationPage.vue'),
  },
  {
    path: '/pe/lock-desk/:losLoanId',
    name: 'lock-loan-details',
    props: true,
    meta: {
      navitem: 'pe_lock_loan_details',
      checkPermissions: true,
    },
    component: () => import('../pages/LockDeskHistoryPage.vue'),
  },
  {
    path: '/pe/pricing/:id/products',
    name: 'configure-products',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/ProductsListPage.vue'),
  },
  {
    path: '/pe/pricing/:configId/products/:id',
    name: 'configure-product',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/ProductDetailsPage.vue'),
  },
  {
    path: '/pe/pricing/:id/channels',
    name: 'configure-channels',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () =>
      import(
        '../pages/ChannelsListPage.vue' /* webpackChunkName: 'channels-list-page' */
      ),
  },
  {
    path: '/pe/pricing/:configId/channels/:id',
    name: 'configure-channel',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/ChannelDetailsPage.vue'),
  },
  {
    path: '/pe/pricing/:id/custom-parameters',
    name: 'configure-custom-parameters',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/CustomParametersListPage.vue'),
  },
  {
    path: '/pe/pricing/:configId/custom-parameters/:id',
    name: 'configure-custom-parameter',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/CustomParameterDetailsPage.vue'),
  },
  {
    path: '/pe/pricing/:configId/lock-desk-workflow-policies/new',
    name: 'lock-desk-workflow-policies-new',
    props: true,
    exact: true,
    meta: {
      checkPermissions: true,
    },
    component: () => import('../pages/LockDeskWorkflowPolicyPage.vue'),
  },
  {
    path: '/pe/pricing/:configId/lock-desk-workflow-policies',
    name: 'lock-desk-workflow-policies',
    props: true,
    meta: {
      checkPermissions: true,
    },
    component: () => import('../pages/LockDeskWorkflowPoliciesPage.vue'),
  },
  {
    path: '/pe/pricing/:configId/lock-desk-workflow-policies/:id',
    name: 'lock-desk-workflow-policy-detail',
    props: true,
    meta: {
      checkPermissions: true,
    },
    component: () => import('../pages/LockDeskWorkflowPolicyPage.vue'),
  },
  {
    path: '/pe/pricing/:id/rules',
    name: 'configure-rules',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/RulesListPage.vue'),
  },
  {
    path: '/pe/mi-rate-card-versions-details/:id/rules',
    name: 'mortgage-insurance-rate-card-configure-rules',
    props: true,
    meta: {
      navitem: 'mi_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/MIRulesListPage.vue'),
  },
  {
    path: '/pe/pricing/:configId/rules/:id',
    name: 'configure-rule',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/RuleDetailsPage.vue'),
  },
  {
    path: '/pe/mi-rate-card-versions-details/:configId/rules/:id',
    name: 'mortgage-insurance-rate-card-configure-rule',
    props: true,
    meta: {
      navitem: 'mi_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/MIRuleDetailsPage.vue'),
  },
  {
    path: '/pe/mi-rate-card-versions-details/:configId/premiums/:id',
    name: 'mortgage-insurance-rate-card-configure-premium',
    props: true,
    meta: {
      navitem: 'mi_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/MIPremiumDetailsPage.vue'),
  },
  {
    path: '/pe/pricing/:configId/products/:productId/inherited-rules/:id',
    name: 'configure-inherited-rule',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/InheritedRuleDetailsPage.vue'),
  },
  {
    path: '/pe/pricing/:configId/rule-groups/:id',
    name: 'configure-rule-group',
    props: true,
    meta: {
      navitem: 'pe_versions',
      checkPermissions: true,
    },
    component: () => import('../pages/RuleGroupDetailsPage.vue'),
  },
  {
    path: '/pe/loan-scenario',
    name: 'loan-scenarios',
    meta: {
      checkPermissions: true,
      navitem: 'pe_test_scenarios',
      newAppPath: '/pe/loan-scenario',
      showPricerAttribution: true,
    },
    component: () => import('../pages/LoanScenarioPage.vue'),
  },
  {
    path: '/pe/loans/analysis',
    name: 'loan-analysis',
    component: () => import('../components/Pricing/OptionsPanel.vue'),
    meta: {
      showPricerAttribution: true,
      showSideNav: true,
    },
  },
  {
    path: '/pe/loans-embed/:id?',
    name: 'loan-pricing-embed',
    meta: {
      showPricerAttribution: true,
      showSideNav: false,
      embedded: true,
    },
    component: () => import('../components/Pricing/OptionsPanel.vue'),
    children: [
      {
        path: 'lock/:lockId',
        name: 'loan-pricing-embed-lock-review',
        meta: {
          navitem: 'pe_loan_pipeline',
          showSideNav: false,
          embedded: true,
        },
        component: () =>
          import('../components/Pricing/lockReview/LockReview.vue'),
      },
    ],
  },
  {
    path: '/pe/loans-embed-options/:id?',
    name: 'loan-pricing-embed-options',
    meta: {
      showPricerAttribution: true,
      showSideNav: false,
      embedded: true,
    },
    component: () => import('../components/Pricing/OptionsPanel.vue'),
    children: [
      {
        path: 'lock/:lockId',
        name: 'loan-pricing-embed-options-lock-review',
        meta: {
          navitem: 'pe_loan_pipeline',
          showSideNav: false,
          embedded: true,
        },
        component: () =>
          import('../components/Pricing/lockReview/LockReview.vue'),
      },
    ],
  },
  {
    path: '/pe/loans/:id',
    name: 'loan-pricing',
    meta: {
      navitem: 'pe_loan_pipeline',
      checkPermissions: true,
      showPricerAttribution: true,
    },
    children: [
      {
        path: 'lock/:lockId',
        name: 'loan-pricing-lock-review',
        meta: {
          navitem: 'pe_loan_pipeline',
          checkPermissions: true,
        },
        component: () =>
          import('../components/Pricing/lockReview/LockReview.vue'),
      },
    ],
    component: () => import('../components/Pricing/OptionsPanel.vue'),
  },
  {
    path: '/pe/rate-alerts',
    name: 'rate-alerts',
    props: true,
    meta: {
      navitem: 'pe_rate_alerts',
      checkPermissions: true,
    },
    component: () => import('../pages/RateAlertListPage.vue'),
  },
];
