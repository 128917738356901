import {
  SET_BUY_SIDE_SHOW_CWF_PRICE_EXCEPTION,
  SET_CURRENT_LOCK_DESK,
  SET_PENDING_CHANGES_BY_SIDE,
  SET_PENDING_PRICE_ADJUSTMENTS,
  SET_PENDING_RATE_ADJUSTMENTS,
  SET_EDITING_RESPONSE,
  CLEAR_PENDING_CHANGES,
  CACHE_EDITING_PAYLOAD,
  SHOW_PE_TOGGLE_CHANGED,
} from './mutationTypes';

import api from '@shared/services/api';
import {
  ADJUSTMENT_TYPE,
  WORKFLOW_LOCK_SIDES,
  LOCK_RATE_ACTIONS,
  PERuleCategories,
  compPaidBy,
  compPaidByTypes,
  LOS_WRITEBACK_QUEUE_ITEM_STATUS,
} from '@shared/constants';

import { strToPrecision } from '@shared/utils/converters';

import {
  getOriginalFieldValue,
  isPriceAdjustment,
  isRateAdjustment,
} from '@pe/services/lockDesk';

import Vue from 'vue';
import { parseMoney } from '@shared/utils';

// This is in a specific order
const FEE_FIELDS = [
  'workflowFee',
  'relockFee',
  'extensionFee',
  'exceptionAmount',
];

/**
 * This will strip fees defined in FEE_FIELDS and remove those properties from the input payload.
 * @param {Object} payload - Object containing a side of the workflow.
 * @return {Object} Returns a new payload that has removed fees.
 */
function stripFeesFromPayload(payload) {
  const newPayload = structuredClone(payload);
  for (const field of FEE_FIELDS) {
    if (FEE_FIELDS.includes(field)) {
      delete newPayload[field];
    }
  }
  return newPayload;
}

/**
 * This method will at the item into the list or replace it if it already exists.
 * This will look directly on the id property if they are similar.
 * @param {Object} item - An item that contains at least an id property.
 * @param {Array<Object>} list - An list for objects that contains at least an id property.
 * @return {*}
 */
function addOrReplace(item, list) {
  const _list = structuredClone(list);
  const index = _list.findIndex(obj => obj.id === item.id);
  if (index === -1) {
    _list.push(item);
  } else {
    _list[index] = item;
  }
  return _list;
}

export const initialState = {
  // not ideal but we want to track when buy side has the toggle on.
  // at some point we might want to have a global setting for this and make it not specific to a component
  buySideShowCwfPriceException: true,

  // current lock desk data
  currentLockDesk: {
    buySide: {},
    sellSide: {},
  },
  // track of what has changed on the lock desk
  pendingChanges: {
    buySide: {},
    sellSide: {},
  },

  // separate container for pending pricing adjustments
  pendingPriceAdjustmentChanges: {
    buySide: [],
    sellSide: [],
  },

  // separate container for pending rate adjustments
  pendingRateAdjustmentChanges: {
    buySide: [],
    sellSide: [],
  },

  // response from API
  editingResponse: {
    buySide: {},
    sellSide: {},
  },

  cachedEditingPayload: null,
  showPeToggleChanged: false,
};

const getters = {
  showPeToggleChanged: state => state.showPeToggleChanged,
  isCurrentLockDeskInitialized: state =>
    (state.currentLockDesk?.buySide &&
      'priceBeforeAdjustments' in state.currentLockDesk.buySide) ||
    (state.currentLockDesk?.sellSide &&
      'priceBeforeAdjustments' in state.currentLockDesk.sellSide),
  // current related
  currentLockDeskBySide: state => side =>
    side === WORKFLOW_LOCK_SIDES.BUY
      ? state.currentLockDesk.buySide
      : state.currentLockDesk.sellSide,
  // pending related
  pendingChangesBySide: state => side =>
    side === WORKFLOW_LOCK_SIDES.BUY
      ? state.pendingChanges.buySide
      : state.pendingChanges.sellSide,
  pendingAdjustmentsByType: state => type => {
    if (isPriceAdjustment(type)) {
      return state.pendingPriceAdjustmentChanges;
    }
    if (isRateAdjustment(type)) {
      return state.pendingRateAdjustmentChanges;
    }
    return [];
  },
  shouldClearSellSide: state => {
    if (Object.keys(state.pendingPriceAdjustmentChanges.sellSide).length) {
      return false;
    }
    return true;
  },
  // editing
  editingResponseBySide: state => side =>
    side === WORKFLOW_LOCK_SIDES.BUY
      ? state.editingResponse.buySide
      : state.editingResponse.sellSide,

  allPendingAdjustments: state => {
    const adjustments = [
      ...state.pendingPriceAdjustmentChanges.buySide,
      ...state.pendingPriceAdjustmentChanges.sellSide,
      ...state.pendingRateAdjustmentChanges.buySide,
      ...state.pendingRateAdjustmentChanges.sellSide,
    ];

    const uniqueAdjustments = [];
    for (const adjustment of adjustments) {
      const index = uniqueAdjustments.findIndex(
        adj => adj.id === adjustment.id,
      );

      if (index === -1) {
        uniqueAdjustments.push(adjustment);
      }
    }
    return uniqueAdjustments;
  },
  isCrossWorkflowExceptionNotDenied: rootGetters => {
    const crossFlowRequestWritebackStatus =
      rootGetters['lockDesk/workflowDetails']?.childLockRequests?.[0]
        ?.writebackStatus;
    return (
      crossFlowRequestWritebackStatus !== LOS_WRITEBACK_QUEUE_ITEM_STATUS.DENIED
    );
  },
  editingPayload: (state, getters, rootState, rootGetters) => {
    if (!getters.isCurrentLockDeskInitialized) {
      return;
    }

    const payload = {
      ...state.currentLockDesk,
      ...state.pendingChanges,
      buySide: {
        ...state.currentLockDesk.buySide,
        ...state.pendingChanges.buySide,
      },
      sellSide: {
        ...state.currentLockDesk.sellSide,
        ...state.pendingChanges.sellSide,
      },
    };

    for (const sideProperty of ['buySide', 'sellSide']) {
      // go through fees in payload. we want to ensure only 1 fee is being applied in the following order:
      // workflowFee, relockFee, extensionFee, priceException
      // order was retrieve from /src/pe/mixins/WorkflowColumnMixin.js#L1014-L1026 (method: updateNetPriceFromFieldChange)
      for (const feeProperty of FEE_FIELDS) {
        const sidePendingChanges = state.pendingChanges[sideProperty];

        // look for fee
        if (feeProperty in sidePendingChanges) {
          // remove all fees
          payload[sideProperty] = stripFeesFromPayload(payload[sideProperty]);

          // apply fee we are interested in
          payload[sideProperty][feeProperty] = sidePendingChanges[feeProperty];

          break;
        }
      }
    }

    // manually manage where adjustments should be since a single adjustment can represent both sides
    for (const pendingAdj of getters.allPendingAdjustments) {
      // remove from buy side if flag is false
      if (!pendingAdj.is_buy_side) {
        const buyIndex = payload.buySide.adjustments.findIndex(
          adj => adj.id === pendingAdj.id,
        );
        if (buyIndex !== -1) {
          payload.buySide.adjustments.splice(buyIndex, 1);
        }
      }

      // remove from sell side if flag is false
      if (!pendingAdj.is_sell_side) {
        const sellIndex = payload.sellSide.adjustments.findIndex(
          adj => adj.id === pendingAdj.id,
        );
        if (sellIndex !== -1) {
          payload.sellSide.adjustments.splice(sellIndex, 1);
        }
      }

      // add to buy side if flag is true
      if (pendingAdj.is_buy_side) {
        payload.buySide.adjustments = addOrReplace(
          pendingAdj,
          payload.buySide.adjustments,
        );
      }

      // add to sell side if flag is true
      // exclude margin adjustments on sell side
      if (
        pendingAdj.is_sell_side &&
        pendingAdj.rule_category !== PERuleCategories.Margin
      ) {
        payload.sellSide.adjustments = addOrReplace(
          pendingAdj,
          payload.sellSide.adjustments,
        );
      }
    }

    return payload;
  },
  // payload to send for lock request's reviewal endpoint
  lockDeskEdits(state) {
    const payload = { buySide: {}, sellSide: {} };

    // list obtained from lockDesk/index.js clearSellSideValues
    // while not all these fields are included in the response I figure it would be easy now to future-proof
    const targetFields = [
      'clearSellSide',
      'exceptionAmount',
      'exceptionComments',
      'exceptionReason',
      'exceptionType',
      'extendBy',
      'extensionFee',
      'investor',
      'investorLoanNumber',
      'lockPeriod',
      'noteRate',
      'productName',
      'priceBeforeAdjustments',
      'relockFee',
      'relockPeriod',
      'netPrice',
      'origination',
      'discount',
      'lenderCredit',
      'allInPrice',
    ];

    for (const sideProperty of ['buySide', 'sellSide']) {
      for (const [key, value] of Object.entries(
        state.editingResponse[sideProperty],
      )) {
        if (targetFields.includes(key) && value?.isChanged) {
          if (
            ['extensionFee', 'relockFee', 'origination', 'discount'].includes(
              key,
            )
          ) {
            payload[sideProperty][key] = value.value.split(' ')[0];
          } else if (key === 'lenderCredit') {
            // Split the lender credit value so we can populate the override requested_lender_credit_amount field
            const values = value.value.split(' ');
            //parese it as money and remove the parentheses
            const amountValue = parseMoney(values[1]).replace(/\(|\)/g, '');
            payload[sideProperty][key] = values[0];
            payload[sideProperty][key + 'Amount'] = amountValue;
          } else {
            payload[sideProperty][key] = value.value;
          }
        }
      }
    }

    return payload;
  },
};

const mutations = {
  [SHOW_PE_TOGGLE_CHANGED](state, v) {
    state.showPeToggleChanged = v;
  },
  [SET_BUY_SIDE_SHOW_CWF_PRICE_EXCEPTION](state, v) {
    state.buySideShowCwfPriceException = v;
  },
  [SET_CURRENT_LOCK_DESK](state, data) {
    state.currentLockDesk = data;
  },
  [SET_PENDING_CHANGES_BY_SIDE](
    state,
    { side = WORKFLOW_LOCK_SIDES.BUY, ...changes },
  ) {
    if ([WORKFLOW_LOCK_SIDES.BUY, 'both'].includes(side)) {
      Vue.set(state.pendingChanges, 'buySide', {
        ...state.pendingChanges.buySide,
        ...changes,
      });
    }

    if ([WORKFLOW_LOCK_SIDES.SELL, 'both'].includes(side)) {
      Vue.set(state.pendingChanges, 'sellSide', {
        ...state.pendingChanges.sellSide,
        ...changes,
      });
    }
  },
  [CLEAR_PENDING_CHANGES](state) {
    Vue.set(
      state,
      'pendingChanges',
      structuredClone(initialState.pendingChanges),
    );
  },
  [SET_PENDING_PRICE_ADJUSTMENTS](state, data) {
    state.pendingPriceAdjustmentChanges = data;
  },
  [SET_PENDING_RATE_ADJUSTMENTS](state, data) {
    state.pendingRateAdjustmentChanges = data;
  },
  [SET_EDITING_RESPONSE](state, response) {
    state.editingResponse = { ...response };
  },
  [CACHE_EDITING_PAYLOAD](state, payload) {
    state.cachedEditingPayload = payload;
  },
};

const actions = {
  async callEditingApi({ state, commit, getters, rootGetters }) {
    // if no initial data has been set exit early
    if (!getters.isCurrentLockDeskInitialized) {
      return;
    }

    // TEMP:
    // workaround until reactivity of editingPayload is manageable
    // payload hasn't changed since last call no need to call again
    if (state.cachedEditingPayload === JSON.stringify(getters.editingPayload)) {
      return;
    }

    const lockRequest = rootGetters['lockDesk/workflowDetails'];
    if (!lockRequest.id) return;
    const response = await api.post(
      `/pe/api/lock-requests/${lockRequest.id}/editing/`,
      getters.editingPayload,
    );
    commit('SET_EDITING_RESPONSE', response);
    commit('CACHE_EDITING_PAYLOAD', JSON.stringify(getters.editingPayload));
    return response;
  },
  clearPendingAdjustments({ commit }) {
    commit(
      'SET_PENDING_PRICE_ADJUSTMENTS',
      structuredClone(initialState.pendingPriceAdjustmentChanges),
    );
    commit(
      'SET_PENDING_RATE_ADJUSTMENTS',
      structuredClone(initialState.pendingRateAdjustmentChanges),
    );
  },
  clearLockDeskState({ commit, dispatch }) {
    commit(
      'SET_CURRENT_LOCK_DESK',
      structuredClone(initialState.currentLockDesk),
    );
    commit('CLEAR_PENDING_CHANGES');
    dispatch('clearPendingAdjustments');
    commit(
      'SET_EDITING_RESPONSE',
      structuredClone(initialState.editingResponse),
    );
    commit('CACHE_EDITING_PAYLOAD', null);
  },
  updateCurrentLockDesk(
    { state, commit, rootGetters },
    showCwfPriceException = null,
  ) {
    // if show cwf is not explicitly passed grab from our state
    const showPe = showCwfPriceException ?? state.buySideShowCwfPriceException;
    const buySideInfo =
      rootGetters['lockDesk/isCrossWorkflow'] && showPe
        ? rootGetters['lockDesk/workflowDetails'].childLockRequests[0]
        : rootGetters['lockDesk/workflowDetails'];
    const sellSideInfo = rootGetters['lockDesk/workflowDetails']?.sellSide;

    // if buyside isn't at least present exit early
    if (!(buySideInfo && Object.keys(buySideInfo).length > 0)) {
      return;
    }

    const { action: lockAction } = buySideInfo;

    let brokerCompAmount = 0;
    const brokerCompPlan = rootGetters['lockDesk/brokerCompPlan'];
    if (brokerCompPlan) {
      const { paidBy } = brokerCompPlan;
      if (paidBy === compPaidBy[compPaidByTypes.LENDER]) {
        brokerCompAmount = strToPrecision(
          brokerCompPlan.calculatedAdjustment * -1 || 0,
          3,
        );
      }
    }

    // buyside
    const buySide = {
      noteRate: buySideInfo.baseRate,
      lockPeriod: getOriginalFieldValue('lockPeriod', buySideInfo),
      priceBeforeAdjustments: getOriginalFieldValue(
        'priceBeforeAdjustments',
        buySideInfo,
      ),
      brokerCompAmount,
      adjustments: [
        ...rootGetters['lockDesk/getTempAdjustmentsBySide'](
          ADJUSTMENT_TYPE.PRICE,
          WORKFLOW_LOCK_SIDES.BUY,
        ),
        ...rootGetters['lockDesk/getTempAdjustmentsBySide'](
          ADJUSTMENT_TYPE.RATE,
          WORKFLOW_LOCK_SIDES.BUY,
        ),
      ],
    };

    if (lockAction === LOCK_RATE_ACTIONS.PRICE_EXCEPTION) {
      buySide['exceptionAmount'] = getOriginalFieldValue(
        'exceptionAmount',
        buySideInfo,
      );
    } else if (lockAction === LOCK_RATE_ACTIONS.EXTENSION) {
      buySide['extensionFee'] = getOriginalFieldValue(
        'extensionFee',
        buySideInfo,
      );
      buySide['extendBy'] = getOriginalFieldValue('extendBy', buySideInfo);
    } else if (lockAction === LOCK_RATE_ACTIONS.RELOCK) {
      buySide['relockFee'] = getOriginalFieldValue('relockFee', buySideInfo);
      buySide['relockPeriod'] = getOriginalFieldValue(
        'relockPeriod',
        buySideInfo,
      );
    }
    // end buyside

    // sellside
    const sellSide = {
      noteRate: getOriginalFieldValue('noteRate', sellSideInfo),
      lockPeriod: getOriginalFieldValue('lockPeriod', sellSideInfo),
      priceBeforeAdjustments: getOriginalFieldValue(
        'priceBeforeAdjustments',
        sellSideInfo,
      ),
      adjustments: [
        ...rootGetters['lockDesk/getTempAdjustmentsBySide'](
          ADJUSTMENT_TYPE.PRICE,
          WORKFLOW_LOCK_SIDES.SELL,
        ),
        ...rootGetters['lockDesk/getTempAdjustmentsBySide'](
          ADJUSTMENT_TYPE.RATE,
          WORKFLOW_LOCK_SIDES.SELL,
        ),
      ],
    };

    if (lockAction === LOCK_RATE_ACTIONS.PRICE_EXCEPTION) {
      sellSide['exceptionAmount'] = getOriginalFieldValue(
        'exceptionAmount',
        sellSideInfo,
      );
    } else if (lockAction === LOCK_RATE_ACTIONS.EXTENSION) {
      sellSide['extensionFee'] = getOriginalFieldValue(
        'extensionFee',
        sellSideInfo,
      );
      sellSide['extendBy'] = getOriginalFieldValue('extendBy', sellSideInfo);
    } else if (lockAction === LOCK_RATE_ACTIONS.RELOCK) {
      sellSide['relockFee'] = getOriginalFieldValue('relockFee', sellSideInfo);
      sellSide['relockPeriod'] = getOriginalFieldValue(
        'relockPeriod',
        sellSideInfo,
      );
    }
    // end sellside

    const payload = {
      orgId: rootGetters['core/userInfo']?.default_org?.id,
      loanAmount: rootGetters['lockDesk/totalLoanAmount'],
      lockAction,
      buySide,
      sellSide,
    };

    commit('SET_CURRENT_LOCK_DESK', payload);
  },
  clearSellSideCurrentLockDesk: function ({ commit, state }) {
    const { buySide, sellSide, ...rest } = state.currentLockDesk;
    commit('SET_CURRENT_LOCK_DESK', {
      ...rest,
      buySide: { ...buySide },
      sellSide: {
        adjustments: [],
        lockPeriod: '',
        noteRate: '',
        priceBeforeAdjustments: '',
      },
    });
    commit('SET_EDITING_RESPONSE', {
      buySide: { ...state.editingResponse.buySide },
      sellSide: {},
    });
    commit('SET_PENDING_PRICE_ADJUSTMENTS', {
      buySide: [...state.pendingPriceAdjustmentChanges.buySide],
      sellSide: [],
    });
    commit('SET_PENDING_RATE_ADJUSTMENTS', {
      buySide: [...state.pendingRateAdjustmentChanges.buySide],
      sellSide: [],
    });
  },
  setAdjustmentsForBestExRestingState(
    { commit, state, rootGetters },
    priceAdjustments,
    rateAdjustments,
  ) {
    const sellSideBestExPricing =
      rootGetters['lockDesk/sellSideBestExecutionPrice'];

    commit('SET_CURRENT_LOCK_DESK', {
      ...state.currentLockDesk,
      buySide: { ...state.currentLockDesk.buySide },
      sellSide: {
        ...state.currentLockDesk.sellSide,
        adjustments: [],
      },
    });

    commit('SET_PENDING_CHANGES_BY_SIDE', {
      side: WORKFLOW_LOCK_SIDES.SELL,
      noteRate: sellSideBestExPricing.baseRate,
      lockPeriod: sellSideBestExPricing.lockPeriod,
      priceBeforeAdjustments: sellSideBestExPricing.basePrice,
    });
    commit('SET_PENDING_PRICE_ADJUSTMENTS', {
      buySide: [...state.pendingPriceAdjustmentChanges.buySide],
      sellSide: priceAdjustments?.filter(adj => adj.is_sell_side) || [],
    });
    commit('SET_PENDING_RATE_ADJUSTMENTS', {
      buySide: [...state.pendingRateAdjustmentChanges.buySide],
      sellSide: rateAdjustments?.filter(adj => adj.is_sell_side) || [],
    });
  },
  updatePendingAdjustments({ state, getters, commit }, adjustment) {
    // If there exists pending adjustments for this type we use those adjustments
    // otherwise default to current lock desk adjustments
    const newPendingAdjustments = {
      buySide:
        getters.pendingAdjustmentsByType(adjustment.type).buySide ||
        state.currentLockDesk?.buySide?.adjustments ||
        [],
      sellSide:
        getters.pendingAdjustmentsByType(adjustment.type).sellSide ||
        state.currentLockDesk?.sellSide?.adjustments ||
        [],
    };

    // BUYSIDE
    const buySideIndex = newPendingAdjustments?.buySide?.findIndex(
      adj => adj.id === adjustment.id,
    );

    // make sure adjustment is in buyside adjustments and updated
    if (adjustment.is_buy_side) {
      newPendingAdjustments.buySide = addOrReplace(
        adjustment,
        newPendingAdjustments.buySide,
      );
    }
    // make sure adjustment is not in buyside adjustments
    else {
      if (buySideIndex !== -1) {
        newPendingAdjustments.buySide.splice(buySideIndex, 1);
      }
    }

    // SELLSIDE
    const sellSideIndex = newPendingAdjustments?.sellSide?.findIndex(
      adj => adj.id === adjustment.id,
    );

    // make sure adjustment is in sellside adjustments and updated
    if (adjustment.is_sell_side) {
      newPendingAdjustments.sellSide = addOrReplace(
        adjustment,
        newPendingAdjustments.sellSide,
      );
    }
    // make sure adjustment is not in sellside adjustments
    else {
      if (sellSideIndex !== -1) {
        newPendingAdjustments.sellSide.splice(sellSideIndex, 1);
      }
    }

    if (isPriceAdjustment(adjustment.type)) {
      commit('SET_PENDING_PRICE_ADJUSTMENTS', newPendingAdjustments);
    } else if (isRateAdjustment(adjustment.type)) {
      commit('SET_PENDING_RATE_ADJUSTMENTS', newPendingAdjustments);
    } else {
      console.warn(
        'Could not update. Adjustment is not a supported adjustment type',
      );
    }
  },
  removeFromPendingAdjustments({ state, commit }, adjustment) {
    let pendingTypeAdjustments = null;

    if (isPriceAdjustment(adjustment.type)) {
      pendingTypeAdjustments = structuredClone(
        state.pendingPriceAdjustmentChanges,
      );
    } else if (isRateAdjustment(adjustment.type)) {
      pendingTypeAdjustments = structuredClone(
        state.pendingRateAdjustmentChanges,
      );
    }

    const newPendingAdjustments = {
      buySide: pendingTypeAdjustments?.buySide || [],
      sellSide: pendingTypeAdjustments?.sellSide || [],
    };

    for (const sideProperty of ['buySide', 'sellSide']) {
      const index = newPendingAdjustments[sideProperty].findIndex(
        adj => adj.id === adjustment.id,
      );
      if (index !== -1) {
        newPendingAdjustments[sideProperty].splice(index, 1);
      }
    }

    if (isPriceAdjustment(adjustment.type)) {
      commit('SET_PENDING_PRICE_ADJUSTMENTS', newPendingAdjustments);
    } else if (isRateAdjustment(adjustment.type)) {
      commit('SET_PENDING_RATE_ADJUSTMENTS', newPendingAdjustments);
    } else {
      console.warn(
        'Could not update. Adjustment is not a supported adjustment type',
      );
    }
  },
};

export default {
  namespaced: true,
  state: structuredClone(initialState),
  getters,
  mutations,
  actions,
};
