<template>
  <div>
    <template v-if="showSideNav && !isLoading">
      <component :is="SideNavComponent" />
    </template>
    <div class="main">
      <px-toast />
      <chat-widget
        v-if="$nliService?.isAnyNLIActive()"
        :messages="[
          {
            sender: 'POLL-E',
            text: 'Hi, I’m Polly AI. I can help you get more done, faster. How can I help you today?',
          },
        ]"
      />
      <div class="mai-wrapper">
        <px-loading-wrapper :loading="isLoading" type="text">
          <router-view :key="$route.path"></router-view>
        </px-loading-wrapper>
      </div>
      <benchmark-bar
        v-if="shouldShowBenchmarks()"
        :benchmarks="benchmarks"
        :refreshed-time="refreshedTime"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { log } from '@shared/utils/logging.js';
import BenchmarkBar from '@shared/components/BenchmarkBar.vue';
import benchmarksMixin from '@shared/mixins/benchmarks.js';
import SideNav from '@shared/components/SideNav/SideNav.vue';
import SideNavMobile from '@shared/components/SideNav/SideNavMobile.vue';
import ChatWidget from './shared/components/Chat/ChatWidget.vue';
import newAppRoutingMixin from '@shared/mixins/newAppRouting';

const MOBILE_WIDTH = 768;

export default {
  name: 'App',
  components: {
    SideNav,
    SideNavMobile,
    BenchmarkBar,
    ChatWidget,
  },
  mixins: [benchmarksMixin, newAppRoutingMixin],
  provide: {
    isMobile() {
      return this.isMobile;
    },
  },
  data: function () {
    return {
      loading: true,
      windowWidth: window.innerWidth || 0,
      showSideNav: false,
    };
  },
  computed: {
    isLoading() {
      return this.loading || this.newAppLoading;
    },
    ...mapGetters({
      userProfile: 'core/userProfile',
      userInfo: 'core/userInfo',
      currentOrganizationId: 'organizations/currentOrganizationId',
      isFetchOrganizationLoading: 'organizations/isFetchOrganizationLoading',
      organization: 'organizations/organization',
    }),
    isMobile() {
      return this.windowWidth <= MOBILE_WIDTH;
    },
    SideNavComponent() {
      return this.isMobile ? 'SideNavMobile' : 'SideNav';
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.meta.title) {
          document.title = `${to.meta.title} | PollyEx`;
        } else {
          document.title = 'PollyEx';
        }

        //side nav
        const queryShowSideNav = to.query?.showSideNav;
        if (typeof queryShowSideNav !== 'undefined') {
          let queryVisibility = queryShowSideNav;
          if (typeof queryShowSideNav === 'string') {
            queryVisibility = queryShowSideNav === 'true';
          }
          this.showSideNav = queryVisibility;
        } else {
          this.showSideNav = to.meta?.showSideNav ?? true;
        }
      },
    },
  },
  async mounted() {
    if (this.currentOrganizationId) {
      await Promise.all([
        this.getUserInfo(),
        this.getPermissions(),
        this.fetchOrganization(),
        this.getUserProfile(),
      ]);
    }

    this.updateApmContext();

    // APP-4438 - vue-router is rendering path: '\' first, instead of the full path, in this case
    // '/loans-embed'causing the side nav to flash before the router loads the correct path.
    // waiting for the router to be ready fixes this initial render of the wrong path.
    this.$router.onReady(() => {
      this.loading = false;
    });

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapActions({
      getUserProfile: 'core/getUserProfile',
      getUserInfo: 'core/getUserInfo',
      getPermissions: 'core/getPermissions',
      fetchOrganization: 'organizations/fetchOrganization',
    }),
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    updateApmContext() {
      if (this.$apm) {
        try {
          this.$apm.setUserContext({
            id: this.userInfo.id,
            email: this.userProfile.user.email,
          });
          this.$apm.addLabels({
            org_name: this.organization.name,
            org_id: this.organization.id,
          });
        } catch (e) {
          log(e);
        }
      }
    },
  },
};
</script>
