import AiIcon from '@shared/assets/images/ai-avatar.svg';
import {
  COPILOT_ACTIONS,
  COPILOT_GETTERS,
  SET_AUTO_PRICING_ENABLED,
  SET_VOCAL_RESPONSE_ENABLED,
} from '@src/shared/store/modules/copilot';

import { mapGetters, mapMutations, mapActions } from 'vuex';

import MessageReaction from './MessageReaction.vue';
import ChatHeader from './ChatHeader.vue';
import ChatWidgetToggle from './ChatWidgetToggle.vue';
import ChatSettings from './ChatSettings.vue';
import ChatMessageList from './ChatMessageList.vue';
import ChatInput from './ChatInput.vue';
import SuggestedActions from './SuggestedActions.vue';

export default {
  components: {
    MessageReaction,
    ChatHeader,
    ChatWidgetToggle,
    ChatSettings,
    ChatMessageList,
    ChatInput,
    SuggestedActions,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      headerText: 'Polly AI',
      otherProfileImage: AiIcon,
      toggleIcon: AiIcon,
      showSettings: false,
      devMode: process.env.NODE_ENV !== 'production',
    };
  },
  computed: {
    ...mapGetters({
      isSending: `copilot/${COPILOT_GETTERS.isSending}`,
      currentMessage: `copilot/${COPILOT_GETTERS.currentMessage}`,
      allMessages: `copilot/${COPILOT_GETTERS.allMessages}`,
      botName: `copilot/${COPILOT_GETTERS.botName}`,
      vocalResponseEnabled: `copilot/${COPILOT_GETTERS.vocalResponseEnabled}`,
      autoPricingEnabled: `copilot/${COPILOT_GETTERS.autoPricingEnabled}`,
    }),
  },
  mounted() {
    this.$nliService.generateSchemas();
  },
  watch: {
    '$route.path'(newPath) {
      this.resetPageBasedState();
      this.$nliService.determineSuggestedActions(newPath);
    },
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
    handleToggleSettings() {
      this.showSettings = !this.showSettings;
    },
    ...mapMutations({
      setVocalResponseEnabled: `copilot/${SET_VOCAL_RESPONSE_ENABLED}`,
      setAutoPricingEnabled: `copilot/${SET_AUTO_PRICING_ENABLED}`,
    }),
    ...mapActions({
      resetPageBasedState: `copilot/${COPILOT_ACTIONS.resetPageBasedState}`,
    }),
  },
};
